const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#01572c", hover: "#4D896B" },
    secondary: { main: "#d12028", hover: "#DF6369" },
    accent: "#01572c",
    info: {
      main: "#01572c",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
