import React from "react";
import Typography from "@mui/material/Typography";
import { menuImages, restaurantImages } from "./gallery";
import { getSwiperImages } from "../../utils/swiper";
import {
  getMerchantAccolades,
  ACCOLADE_FOOD_HYGIENE,
  ACCOLADE_HALAL,
  ACCOLADE_TRIP_ADVISOR_TOP_RATED,
} from "../../utils/merchant";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to Esher Pizza, where happiness is served in triangles! We are
        proud to be opening our doors this summer to share with the people of
        Esher our passion for delicious pizza.
      </Typography>
      <Typography variant="body1" paragraph>
        Esher Pizza was born out of years of recipe development by restaurant
        owner, Bahar. Teaming up with his best mate and trained head chef
        Elliot, together they have created a menu that showcases the very best
        flavour combinations. Every pizza has been carefully crafted using the
        finest and freshest ingredients available.
      </Typography>
      <Typography variant="body1" paragraph>
        Grab a slice of happiness!
      </Typography>
      <Typography variant="body1" paragraph>
        Esher Pizza
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return getSwiperImages(menuImages);
}

export function getGalleryRestaurantImages() {
  return getSwiperImages(restaurantImages);
}
